@mixin drawer {
    box-shadow: 0px 0px 1px rgb(77, 77, 77);
    position: fixed;
    transition-duration: .4s;
    background-color: #03101A;
    height: 100vh;
    overflow-y: auto;

    .logo {
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        & img {
            width: 50%;
        }
    }

    .activeLink {
        color: #fff;
        background-color: #081B29; 
        // padding: 8px 0;

        & svg {
            color: #fff
        }
    }

    .inActiveLink {
        color: #ffffff; 
        // padding: 8px 0;

        & svg {
            color: #fff
        }
    }

    .activeSubLink {
        font-weight: 600;
        font-size: medium;
    }
    .MuiTypography-root {
        font-family: "KiaSignatureLight";
        font-size:14px;
      }
}

.MuiTypography-root {
    font-family: "KiaSignatureLight";
    font-size:14px;
  }
.open {
    @include drawer();
    width: 22%;
}

.close {
    @include drawer();
    width: 4%;
}

.menuUnstyledList{
    list-style: disc;
    list-style-position: inside;
    margin-inline-start: 20px;
    margin-block: 0px;
    padding-bottom: 10px;
    li{
        padding: 8px 0;
        a{
            opacity: 0.5;
            font-family: "KiaSignatureLight";
            font-size: 14px;
            color: #fff;
            &:hover{
                opacity: 1;
            }
        }
    }
}