// modal
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500;
}

// divider
.divider1 {
    width: 5px;
    height: 25px;
    border-radius: 20;
    background: #C4D7E5;
}

// divider
.divider2 {
    width: 5px;
    height: 25px;
    border-radius: 20;
    background: #05141F;
}


// divider
.divider3 {
    width: 1px;
    height: 25px;
    border-radius: 20;
    background: #9EBCD3;
}

// divider
.divider4 {
    height: 0.5px;
    border-radius: 20;
    background: #00000034;
}


// card
.card1 {
    border: 1px solid #87A8C1;
    padding: 10px !important;
    border-radius: 10px !important;
}

.card2 {
    border: 1px solid #C9E2FB;
    padding: 10px !important;
    border-radius: 4px !important;
    box-shadow: 0px 4px 4px 0px #DDE6EE !important;
    min-height:80px;
    box-sizing:border-box;
    display:flex;
    align-items:center;
    .card2Inner{
        padding:10px 0px;
        align-items: center;
        .card2Content{
            font-size:13px;
            font-weight: 600 !important;
            line-height:17px;
            color:#5B6770;
        }
    }
}


// card
.card3 {
    border: 1px solid #5B7D9A;
    padding: 5px !important;
    border-radius: 10px !important;
    box-shadow: none !important;
}


// link
.link {
    color: #05141F;
}

// link
.linkLight {
    color: #fff;
    text-decoration: none;
}
.btn-no-property{
    // background: none !important;
    span{
        display: none;
    }
}

// button
.btn1 {
    background-color: #627685 !important;
    color: #fff !important;
}

// button
.btn2 {
    background-color: #E5E8EB !important;
    color: #333F4A !important;
    border-radius: 5px !important;
    padding: 0 !important;
}


// accordion
.accordion1 {
    border: 1px solid rgb(197, 227, 250);
    border-radius: 10px !important;
    color: #000 !important;
}

// no data
.noData {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    & img{
        width: 60%;
    }
}



// datagrid
.MuiDataGrid-columnHeader {
    background-color: #05141F !important;
    color: #fff;
}

.MuiDataGrid-columnHeaderCheckbox {
    .MuiSvgIcon-root {
        color: #fff !important;
    }
}

.MuiDataGrid-columnsContainer,
.MuiDataGrid-cell {
    border: 0.5px solid #f0f3f7 !important;
}

.MuiDataGrid-sortIcon,
.MuiDataGrid-menuIconButton {
    color: #fff;
}

.pageTitle{
    font-size:17px !important;
    font-family: "KiaSignatureRegular" !important;
    line-height: 21px !important;
    color:#05141F;
}


// Dhruv css
.color-white{
    color: white !important;
}
.bg-white{
    background: #ffffff !important;
}
.bg-blue{
    background: #0A5FB4 !important;
}
.bg-light-blue{
    background: #EBF6FF !important;
}
.bg-light-green{
    background: #E1FFF7 !important;
}
.bg-light-red{
    background: #FBC6BC !important;
}
.fs-12{
font-size: 12px !important;
}
.fs-13{
    font-size: 13px !important;
}
.fs-14{
    font-size: 14px !important;
}
.fs-15{
    font-size: 15px !important;
}
.fs-16{
    font-size: 16px !important;
    }
.fs-17{
font-size: 17px !important;
}
.fs-20{
    font-size: 20px !important;
}
.fs-21{
    font-size: 21px !important;
}
.fw-400{
    font-weight: 400 !important;
    }
.fw-600{
font-weight: 600 !important;
}
.fw-800{
    font-weight: 800 !important;
    }
.p-relative{
    position: relative !important;
}
.d-flex{
    display: flex !important;
}
.text-center{
    text-align: center !important;
}
.text-end{
    text-align: end !important;
}
.text-start{
    text-align: start !important;
}
.justify-center{
    justify-content: center !important;

}
.mt-10{
    margin-top: 10px !important;
}
.mb-10{
    margin-bottom: 10px !important;
}
.m-auto{
    margin: auto !important;
}
.mx-auto{
    margin-left: auto !important;
    margin-right: auto !important;
}
.my-auto{
    margin-top: auto !important;
    margin-bottom: auto !important;
}
.f-grow{
    flex-grow: 1 !important;
}
.flex-wrap{
    flex-wrap: wrap !important;
}
.justify-content-between{
    justify-content: space-between !important;
}
.h-100{
    height: 100% !important;
}
.w-50{
    width: 50% !important;
}
.w-100{
    width: 100% !important;
}
.border-radious-14{
    border-radius: 14px !important;
}
.lightredcard{
    min-height: 66px;
    border: 1px solid #FBC6BC;
    border-radius: 4px;
    box-shadow: 0px 3px 0px 0px #FBC6BC99;
    width: 100%;
    max-width: 240px;
}
.lightredcard.active{

    border: 1px solid #0A5FB4 !important;
    box-shadow: 0px 3px 0px 0px #0A5FB4 !important;

}
.greencard{
    // padding: 16px 11px;
    min-height: 66px;
    border: 1px solid #84E3B5;
    border-radius: 4px;
    box-shadow: 0px 3px 0px 0px #60C1A480;
    width: 100%;
    max-width: 240px;

}
.greencard.active{

    border: 1px solid #0A5FB4 !important;
    box-shadow: 0px 3px 0px 0px #0A5FB4 !important;

}

.greencard .content {
width: 65%;
border-radius: 4px 0px 0px 4px;
}
.lightredcard .content {
    width: 65%;
    border-radius: 4px 0px 0px 4px;
    }
.greencard .side-content{
width: 35%;
padding:16px 7px;
border-radius: 0px 4px 4px 0px;
}
.lightredcard .side-content{
    width: 35%;
    padding:16px 7px;
    border-radius: 0px 4px 4px 0px;
    }
.greencard.active {
    .content{
        color: #ffffff !important;
        background: #0A5FB4  !important;
    }
    svg path{
        fill: #ffffff;
        color: #ffffff;
        fill-opacity: 1 !important;
    }
}
.lightredcard.active {
    .content{
        color: #ffffff !important;
        background: #0A5FB4  !important;
    }
    svg path{
        fill: #ffffff;
        color: #ffffff;
        fill-opacity: 1 !important;
    }
}
.rightseperator{
    margin-right:20px ;
}
.rightseperator::after{
        content: "";
        width: 11.5px;
        height: 100%;
        background: url(../assets/svg/rightseperator.svg);
        position: absolute;
        right: -19px;
        top: 0px;
        background-repeat: no-repeat;
        background-size: contain;
}

.select-btn{
    background: #EBF6FF !important;
    color: #05141F !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    text-align: center !important;
    height: 55px;
    padding: 9.5px 20.8px !important;
    border-radius: 4px !important;
    min-width: auto !important;
}
.black-btn{
    background: #05141F !important;
    color: #FFFFFF !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    text-align: center !important;
    padding: 17px 30px !important;
    border-radius: 7px !important;
}
.white-btn{
    background: #ffffff !important;
    color: #05141F !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    text-align: center !important;
    padding: 17px 30px !important;
    border-radius: 7px !important;
    border: 1px solid #0D0D0D !important;
}
.select-btn.active{
    background: #0A5FB4 !important;
    color: #ffffff !important;
}
.flex-column{
    flex-direction: column !important;
}

.upload-box{
    display: flex;
    background: #E2F1FF;
    border-radius: 14px;
    max-width: 213px;
    width: 100%;
    height: 181px;
}
.cstm-inputfield{
    border-radius: 7px !important;
    background: #ffffff;
    input{
        padding-block:12px !important ;
    }
    fieldset{
        border: 1px solid #B2C2CE !important;
    }
}

.btn-more{
    background: #D3E1EF !important;
    border-radius: 5px !important;
    font-size: 10px !important;
    font-weight: 400 !important;
    line-height: 12px !important;
    padding: 11px 30px !important;
    gap: 9px !important;
}

.link-btn{
    color: #A7B1BB !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    text-align: center !important;
    padding: 0px !important;
    border-radius: 0px !important;
    border-bottom: 1px solid #A7B1BB !important;

}
// .layout-sidecard-panel-btn{
//     max-width:108px !important;
//     width:47% !important;
//     height:75px !important;
//     border-radius:4px !important;
//     box-shadow:none !important;
//     border: 1px solid #E4F1FF !important;
//     padding:20px 4px !important;
//     cursor:pointer !important;
//     &.active{
//         background: #0A5FB4 !important;
//         color: #ffffff !important;
//         svg{
//             color: #ffffff !important;
//             path{
//                 fill: #ffffff !important;
//             }
//             circle{
//                 fill: #0A5FB4 !important;
//                 stroke: #ffffff !important;
//             }
//         }
//     }
// }
.layout-sidecard-panel-btn{
    // max-width:108px !important;
    // width:47% !important;
    width:108px !important;
    flex-grow: 1;
    height:75px !important;
    // background:#FBFBFB !important;
    border-radius:4px !important;
    box-shadow:none !important;
    border: 1px solid #E4F1FF !important;
    padding:20px 4px !important;
    cursor:pointer !important;
    &.active{
        background: #0A5FB4 !important;
        color: #ffffff !important;
        svg{
            color: #ffffff !important;
            path{
                fill: #ffffff !important;
            }
            circle{
                fill: #0A5FB4 !important;
                stroke: #ffffff !important;
            }
        }
    }
}

.download-btn{
    background: #05141F !important;
    border-radius: 100% !important;
    height: 31px !important;
    width: 31px !important;
    min-width: 31px !important;
}
.solidtable{
   border: 1px solid #D1E4F7;
   border-radius: 8px !important;
    thead{
        background-color: #f9fcff !important;
        th{
        background-color: #f9fcff !important;
        }
    }
    .MuiTableRow-root:nth-of-type(odd) {
        background-color: #f9fcff !important;
    }
    .MuiTableRow-root:nth-of-type(even) {
        background-color: #f9fcff !important;
    }
}
.MuiTableRow-root:nth-of-type(odd) {
    background-color: #ffffff !important;
}
.MuiTableRow-root:nth-of-type(even) {
    background-color: #F0F8FF !important;
}


table {
    th{
        // padding: 0px !important;
    }
    td{
        padding: 0px 16px !important;
        min-height: 43px !important;
    }
    .doneBtn{
        border: 1px solid #70C094 !important;
        background: #EDFFF5 !important;
        font-size: 13px !important;
        font-weight: 400 !important;
        line-height: 18px !important;
        text-align: center !important;
        color: #1C7C04 !important;
        min-width: 90px !important;
        padding: 4px 10px !important;
        border-radius: 4px !important;
        text-transform: capitalize !important;
    }
    .notdoneBtn{
        border: 1px solid #FF7D7D !important;
        background: #FFE0E0 !important;
        font-size: 13px !important;
        font-weight: 400 !important;
        line-height: 18px !important;
        text-align: center !important;
        color: #F92306 !important;
        min-width: 90px !important;
        padding: 4px 10px !important;
        border-radius: 4px !important;
        text-transform: capitalize !important;
    }
    .DownloadBtn{
        border: 1px solid #8FBDE3 !important;
        background: #EBF6FF !important;
        font-size: 13px !important;
        font-weight: 400 !important;
        line-height: 18px !important;
        text-align: center !important;
        color: #05141F !important;
        min-width: 90px !important;
        padding: 4px 10px !important;
        border-radius: 7px !important;
        text-transform: capitalize !important;
    }
}

.layout-blue-btn{
    background: #0A73C1 !important;
    color: #ffffff !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    line-height: normal !important;
    font-weight: 400 !important;
    min-height: 35px;
    text-transform: capitalize !important;
}

.layout-lightblue-btn{
    background: #C9E2FB !important;
    color: #05141F !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    line-height: normal !important;
    font-weight: 400 !important;
    min-height: 35px;
    text-transform: capitalize !important;
}
.filterField2{
    margin-top: 6px !important;
    color: #05141F !important;
    border-radius: 5px !important;
    padding: 3px 9px !important;
    font-size: 13px !important;
    font-family: "KiaSignatureRegular" !important;
    border: 1px solid #A2B9C9 !important;
    background: transparent !important;
    .MuiInput-underline{
        background: transparent !important;
    }
    .MuiInputBase-root::before{
        display: none;
    }
    .MuiInputBase-root::after{
        display: none;
    }
}
.filterField2::before , .filterField2::after{
    display: none !important;
}

.popup-header-rev{
    flex-direction: column-reverse;
    .MuiCardHeader-action{
        margin-left: auto;
    }
    .MuiCardHeader-content span{
        font-weight: 600 !important;
        width: 100%;
        text-align: center;
        color: #05141F;
    }
}


.uploadedfilename{
    margin-top: 10px;
    width: 190px;
    display: flex;
    border-radius: 117px;
    padding: 3px 10px;
    border: 1px solid #A9BBCC;
    background: #ffffff;
    p{  
        margin-block: auto;
        width: calc(190px - 24px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: normal !important;
    }
    svg{
        cursor: pointer;
        width: 24px;
        margin-left: auto !important;
    }
}

.order-card{
    padding: 13px 26px 20px 27px !important;
    color: #05141F !important;
    box-shadow: 0px 4px 24px 0px #E0F0FF !important;
    .MuiCardHeader-title{
        font-weight: 600 !important;
        color: #05141F !important;
        font-size: 12px !important;
    }
    .MuiCardHeader-subheader{
        font-weight: 600 !important;
        color: #157DC9 !important;
        font-size: 14px !important;
    }
}

.cstm-tablecell1{
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #05141F;
    padding:15px 10px 15px 18px !important;
}

.cstm-tablecell2{
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #05141F;
    padding:15px 5px 15px 0px !important;
    border-right: 1px solid #D1E4F7 !important;
}
.cstm-tablecell2::before{
    content: ":";
    padding-right: 10px !important;
}

.no-border{ border: none !important;}