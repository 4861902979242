    .customModal>.css-4pb9x1-MuiBackdrop-root-MuiModal-backdrop {
        background-color: rgba(5, 20, 31, 0.9) !important;
        backdrop-filter: blur(10px);
    }

    .customModal_md {
        width: 100%;
        max-width: 665px;
        @media screen and (max-width: 768px) {
            width: 80%;
        }
    }
    .customModal_sm {
        width: 100%;
        max-width: 500px;
        @media screen and (max-width: 768px) {
            width: 80%;
        }
    }

    .title {
        font-size: 30px !important;
        font-weight: 600;
    }

    .subtitle {
        font-size: 18px !important;
    }
