@mixin body{
    position: absolute;
    top: 12%;
    z-index: -1;
    height: 88%;
    transition-duration: .2s
}

.open{
    @include body();
    left: 24%;
    width: 74%;
}

.close{
    @include body();
    left: 6%;
    width: 92%;
}